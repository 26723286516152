import { useTranslation } from "react-i18next";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import "../../input.css";

const StickyCta = () => {
  const [t] = useTranslation();
  return (
    <div className="fixed bottom-0 w-full flex flex-col justify-center z-30">
      <div className="h-[100px] w-full bg-gradient-to-t from-steelGray to-transparent " />
      <div className="bg-steelGray flex justify-center flex-row items-center px-5 py-5 gap-5">
        <span className="md:text-xl text-sm  text-center py-1 font-semibold">
          {t("sticky_cta.text_1")}
        </span>
        <button
          onClick={() =>
            (window.location.href =
              "https://wa.me/5548999816407?text=Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20os%20servi%C3%A7os%20da%20Lean%20it%20101")
          }
          id="academy-button"
          className="flex md:justify-start justify-center"
          target="_blank"
        >
          <span className="md:p-3 md:px-4 p-2 rounded-full md:text-2xl text-xl bg-pyramid-17 font-extrabold hover:bg-pyramid-19 drop-shadow-lg">
            <WhatsAppIcon
              className="text-white mr-2"
              fontSize="large"
              aria-label="Insights Icon"
            />
            {t("sticky_cta.button")}
          </span>
        </button>
      </div>
    </div>
  );
};

export default StickyCta;
