// import './AcademySalesPage.scss'
import "../../input.css";
import HeroSection from "./HeroSection";
// import LeanPyramidSection from "./LeanPyramidSection";
// import ModulesSection from './ModulesSection/ModulesSection'
// import HotmartSection from './HotmartSection'
import ServicesSection from "./ServicesSection";
// import TeacherSection from "./TeacherSection";
// import TestimonialsPage from "./TestimonialsSection/TestimonialsPage";
// import ScarcitySection from './ScarcitySection'
// import WarrantySection from './WarrantySection'
// import LeanPyramidHistory from "./LeanPyramidHistory";
// import LeanPyramidBenefitsSection from "./LeanPyramidBenefitsSection";
// import OfferSection from './OfferSection'
// import FaqSection from './FaqSection'
import StickyCta from "./StickyCta";
// import Footer from "./Footer";
// import BonusSection from './BonusSection'
// import AcademyNetflixSection from './AcademyNetflixSection'

import TransformativeSolutionsSectionsSection from "./TransformativeSolutionsSection";
import ApproachSection from "./ApproachSection";
// import ModulesSection from "./ModulesSection/ModulesSection";
import SuccessStoriesSection from "./SuccessStoriesSection";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import CustomerLogosSection from "./CustomerLogosSection";

const scrollToSection = (sectionId) => {
  const section = document.getElementById(sectionId);
  if (section) {
    const offsetTop = section.offsetTop;
    window.scrollTo({
      top: offsetTop - 80,
      behavior: "smooth",
    });
  }
};

const AcademySalesPage = () => {
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const section = params.get("section");

    if (section) {
      scrollToSection(section);
    }
  }, [location]);

  return (
    <div
      id="home-page"
      className="h-full w-full flex flex-col font-Barlow text-white  bg-vulcan"
    >
      <StickyCta className="relative bottom-0" />
      <HeroSection />
      <ServicesSection />
      <TransformativeSolutionsSectionsSection />
      <CustomerLogosSection />
      <SuccessStoriesSection />
      <ApproachSection />
      {/* <TeacherSection /> */}
      {/* <TestimonialsPage /> */}
      {/* <ModulesSection /> */}
      {/* <LeanPyramidHistory />
      <LeanPyramidSection />
      <LeanPyramidBenefitsSection /> */}
      {/* <AcademyNetflixSection />
      <BonusSection />
      <HotmartSection />
      <OfferSection />
      <ScarcitySection />
      <WarrantySection />
      <FaqSection /> */}
    </div>
  );
};

export default AcademySalesPage;
